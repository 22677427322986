/*===============
  HERO
===============*/

.hero {
  position: relative;
  @include respond(phone) {
    text-align: center;
  }
  &__image {
    position: relative;
    min-height: 600px;
    transition: all 2s;
    z-index: 99;
    display: flex;
    justify-content: flex-end;

    @include respond(desktop) {
      min-height: 500px;
    }

    @include respond(laptop) {
      min-height: 500px;
      justify-content: center;
    }

    .image-shape {
      background-color: $color-primary;
      display: flex;
      justify-content: center;
      width: 65%;
      height: 500px;
      clip-path: polygon(
        10% 0,
        90% 0,
        100% 10%,
        100% 90%,
        90% 100%,
        10% 100%,
        0 90%,
        0 10%
      );
      position: absolute;
      bottom: 0;
      @include respond(big-desktop) {
        width: 72%;
        height: 450px;
      }
      @include respond(laptop) {
        width: 75%;
        height: 350px;
        top: auto;
      }
      @include respond(mini-phone) {
        width: 100%;
      }
    }

    [class*='image-shape-'] {
      position: absolute;
      top: 5%;
      right: -10%;
      width: 150px;
      height: 150px;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
      animation: animationFramesTwo 12s infinite linear alternate;
      @include respond(phone) {
        display: none;
      }
    }

    .image-shape-2 {
      top: 5%;
      left: 20%;
      @include respond(big-desktop) {
        top: 10%;
      }
      @include respond(desktop) {
        top: 0%;
      }
      @include respond(laptop) {
        top: 10%;
        left: 5%;
      }
    }
    .image-shape-3 {
      top: auto;
      right: -10%;
      bottom: -12%;
      @media screen and (max-width: 1600px) {
        right: -5%;
      }
      @include respond(desktop) {
        right: 0%;
        bottom: -15%;
      }
      @media screen and (max-width: 400px) {
        right: auto;
      }
    }
    img {
      position: absolute;
      left: auto;
      right: 30px;
      bottom: 0px;
      height: 100%;

      @include respond(desktop) {
        right: 20px;
      }

      @include respond(laptop) {
        left: 50%;
        transform: translateX(-50%);
        height: 500px;
      }
    }

    &__badge-1 {
      left: 5%;
      top: 30%;
      background-color: $color-light;
      color: $color-secondary;
      h3 {
        color: $color-secondary;
      }
      @include respond(desktop) {
        left: 0;
        top: 15%;
        width: 170px !important;
      }
      @include respond(laptop) {
        top: 35%;
      }
      @include respond(tablet) {
        left: -30px;
      }
      @include respond(phone) {
        top: auto;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__badge-2 {
      left: 10%;
      top: auto;
      bottom: 10%;
      background-color: $color-light;
      color: $color-secondary;

      @include respond(desktop) {
        left: 0;
      }
      @include respond(phone) {
        bottom: 15%;
      }
      @include respond(mini-phone) {
        left: 0px;
        right: auto;
      }
    }

    &__badge-3 {
      right: -30%;
      top: 50%;
      transform: translateY(-50%);
      background-color: $color-light;
      color: $color-secondary;

      @media screen and (max-width: 1700px) {
        right: -7%;
        top: auto;
        bottom: 10%;
        transform: translateY(0);
      }
      @include respond(big-desktop) {
        right: 0;
      }
      @include respond(phone) {
        bottom: 15%;
      }
    }
  }
}

[dir=rtl] {
  .hero__image{
  [class*='image-shape-'] {
    right: auto;
    left: -10%;
  }

  .image-shape-2 {
    left: auto;
    right: 20%;
    @include respond(laptop) {
      right: 5%;
    }
  }
  .image-shape-3 {
    left: -10%;
    right: auto;
    @media screen and (max-width: 1600px) {
      left: -5%;
    }
    @include respond(desktop) {
      left: 0%;
    }
    @media screen and (max-width: 400px) {
      left: auto;
    }
  }
  img {
    right: auto;
    left: 30px;
    @include respond(desktop) {
      left: 20px;
    }

    @include respond(laptop) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__badge-1 {
    right: 5%;
    left: auto;
    @include respond(desktop) {
      right: 0;
    }
    @include respond(tablet) {
      right: -30px;
    }
    @include respond(phone) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__badge-2 {
    right: 10%;
    left: auto;
    bottom: 10%;
    @include respond(desktop) {
      right: 0;
    }
    @include respond(mini-phone) {
      right: 0px;
      left: auto;
    }
  }

  &__badge-3 {
    left: -30%;
    right: auto;
    @media screen and (max-width: 1700px) {
      left: -7%;
    }
    @include respond(big-desktop) {
      left: 0;
    }
  }
  }
}

[class*='hero__image__badge'] {
  position: absolute;
  padding: 10px 20px;
  border-radius: 5px;
  width: 205px;
  box-shadow: 2px 0px 15px 5px $color-shadow;
  @include respond(phone) {
    padding: 10px;
    width: 185px;
  }
  @include respond(mini) {
    width: 160px;
  }
  @include respond(mini) {
    width: 135px;
  }
  h3 {
    font-size: 32px;
    @include respond(phone) {
      font-size: 20px;
    }
  }
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  opacity: 0.3;
  background-color: $color-primary;
  left: -30px;
  box-shadow: 2px 0px 15px 5px $color-shadow;
}
.x1 {
  animation: moveCircles 10s linear infinite alternate;
  top: 150px;
}

[dir=rtl] {
  .circle {
    left: auto;
    right: -30px;
  }
}

///// HERO DARK VERSION  /////
.theme-dark {
  .hero {
    background-color: $color-secondary;
    color: $color-light;
    &__title {
      color: $color-light;
    }
    &__desc {
      color: $color-light;
    }
    &__image {
      .image-shape {
        background-color: $color-primary;
        color: $color-light;
      }
      &__badge-1 {
        background-color: $color-dark-primary;
        color: $color-light;
        h3 {
          color: $color-light;
        }
      }
      &__badge-2 {
        background-color: $color-dark-primary;
        color: $color-light;
        h3 {
          color: $color-light;
        }
      }
      &__badge-3 {
        background-color: $color-dark-primary;
        color: $color-light;
        h3 {
          color: $color-light;
        }
      }
    }
  }
}

/*===============
  HERO 2
===============*/
.hero2 {
  @include respond(laptop) {
    padding-top: 130px;
  }
  &__right {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &--img1,
    &--img2,
    &--img3 {
      position: relative;
      margin-bottom: 30px;
      margin-top: 30px;
      border: 8px solid $color-light;
      &:before {
        position: absolute;
        width: 0;
        height: 0;
        content: '';
        z-index: 1;
        top: -10px;
        left: -10px;
        border-top: 25px solid $color-light;
        border-left: 25px solid $color-light;
        border-right: 25px solid transparent;
        border-bottom: 25px solid transparent;
        transform: rotate(0deg);
      }
    }
    &--col1,
    &--col2 {
      width: calc(50% - 10px);
      z-index: 1;
    }
    &--img2 {
      margin-top: 80px;
    }
  }

  &__trusted--badge {
    position: absolute;
    bottom: 30px;
    left: 40%;
    transform: translateX(-60%);
    animation: upDown 3s linear infinite alternate;
    z-index: 2;
    padding: 10px 20px;
    box-shadow: -8px 8px 0px 0px $color-primary;
    width: 160px;
    text-align: center;
    background-color: $color-light;
    h5 {
      font-size: 30px;
    }
    @include respond(mini) {
      left: 2%;
    }
  }
  &__badge--text {
    color: $color-secondary;
  }
  &__badge--item {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      img {
        width: 36px;
        height: 36px;
        object-fit: cover;
      }
      border-radius: 100%;
      border: 1px solid $color-light;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:nth-child(2) {
        position: relative;
        left: -5px;
        z-index: 10;
      }
      &:nth-child(3) {
        position: relative;
        left: -15px;
        z-index: 20;
        color: $color-light;
        font-size: 12px;
      }
      &:nth-child(3):before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $color-secondary;
        left: 0;
        top: 0;
        position: absolute;
        z-index: -1;
        border-radius: 100%;
      }
    }
  }
}
/////  HERO 2 DARK VERSION  /////
.theme-dark {
  .hero2 {
    background-color: $color-secondary;

    &__right::after {
      background-color: $color-secondary;
    }
    &__right::before {
      background-color: $color-dark-primary;
    }
    &__trusted--badge {
      background-color: $color-dark-primary;
      color: $color-light;
      h3 {
        color: $color-light;
      }
    }
  }
}

/*===============
  HERO 3
===============*/

.hero3 {
  position: relative;
  background-color: $color-tertiary;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: $color-primary;
    opacity: 0.5;
    top: 90px;
    right: -300px;
    animation: leftShape 3s linear infinite alternate;
    @include respond(desktop) {
      display: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: $color-primary;
    opacity: 0.7;
    bottom: 170px;
    right: -200px;
    animation: leftShape 3s linear infinite alternate;
    animation-delay: 7s;
    @include respond(desktop) {
      display: none;
    }
  }

  &__content {
    padding-right: 25px;
    z-index: 10;
    @include respond(laptop) {
      padding-bottom: 25px;
    }
    @include respond(phone) {
      text-align: center;
    }
    &__video-btn {
      position: relative;
      .video-btn {
        left: 15px;
      }
      .waves-block {
        .waves {
          left: 0;
          top: 0;
          margin-left: 0;
          margin-top: auto;
          bottom: -15px;
          background-color: $color-primary;
        }
      }
    }
  }
  &__image {
    position: relative;
    width: 550px;
    height: 550px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @include respond(desktop) {
      width: 465px;
      height: 465px;
    }
    @include respond(phone) {
      width: 370px;
      height: 370px;
    }
    @include respond(mini) {
      width: 300px;
      height: 300px;
    }
    &::after {
      position: absolute;
      height: 100%;
      width: 100%;
      content: '';
      border: 2px dashed $color-primary;
      left: 0;
      top: 0;
      border-radius: 50%;
      z-index: -1;
      animation: rotate-animation 50s infinite linear;
    }
    img {
      width: 100%;
      max-height: 100%;
      border-radius: 50%;
      object-fit: contain;
      padding: 5px;
    }
    &__icon {
      text-align: center;
      position: absolute;
      width: 80px;
      height: 80px;
      color: $color-light;
      font-size: 20px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 0px 15px 5px $color-shadow;
      transition: all 0.5s;
      font-size: 40px;
      padding: 8px;
      @include respond(phone) {
        width: 70px;
        height: 70px;
        font-size: 30px;
      }
      p {
        background-color: $color-dark-primary;
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        opacity: 0;
        transition: opacity 0.5s;
        border-radius: 50%;
        color: $color-light;
        font-size: 14px;
        position: absolute;
        @include respond(phone) {
          font-size: 12px;
        }
      }
      &:hover > p {
        opacity: 1;
      }
      &.identity {
        right: 20px;
        top: 80px;
        background-color: $azure-radiance;
        @include respond(desktop) {
          right: 0px;
        }
        @include respond(mini-phone) {
          top: 60px;
        }
      }
      &.relations {
        left: 20px;
        top: 80px;
        background-color: $dodger-blue;
        @include respond(desktop) {
          left: 0px;
        }
        @include respond(mini-phone) {
          top: 60px;
        }
      }
      &.event {
        left: -15px;
        top: 70%;
        transform: translateY(-50%);
        background-color: $amethyst;
        @include respond(phone) {
          left: -5px;
        }
      }
      &.advertising {
        right: -15px;
        top: 70%;
        transform: translateY(-50%);
        background-color: $guardsman-red;
        @include respond(phone) {
          right: -5px;
        }
      }
      &.mail {
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $red-ribbon;
      }
    }
  }
  .video-btn {
    position: inherit;
    color: $color-light;
    background-color: $color-primary;
    margin: 0;
  }
}

[dir=rtl] {
  .hero3 {
    .btn__primary {
      margin-left: 25px;
    }
  }
}

/////  HERO 3 DARK VERSION  /////
.theme-dark {
  .hero3 {
    background-color: $color-secondary;
    &__title,
    &__desc {
      color: $color-light;
    }
  }
}

/////  HERO VERSION 04  /////
.hero4 {
  overflow: hidden;
  &__content {
    padding-top: 80px;
    padding-bottom: 80px;
    @include respond(phone) {
      text-align: center;
    }
    @include respond(laptop) {
      padding-top: 50px;
      padding-bottom: 40px;
    }
  }
  //HERO 4 ORBIT CSS
  &__orbit {
    display: grid;
    place-content: center;
    min-height: calc(100vh - 210px);

    @include respond(laptop) {
      min-height: calc(600px);
    }
    @include respond(phone) {
      min-height: calc(450px);
    }
    @include respond(mini-phone) {
      min-height: calc(300px);
    }
    .orbit {
      .center-image {
        width: 12rem;
        position: relative;
        z-index: 10;
        img {
          position: absolute;
          border-radius: 20px;
          transition: opacity 500ms;
          &:not(:first-child):hover {
            opacity: 0;
          }
        }
        @include respond(phone) {
          width: 10rem;
        }
        @include respond(mini-phone) {
          width: 7.5rem;
        }
        @include respond(extraMini) {
          width: 6.5rem;
        }
      }
      ul {
        display: grid;
        place-items: center;
        width: 12rem;
        height: 12rem;
        position: relative;
        list-style: none;
        transform-origin: center;
        animation: orbit 120s linear infinite;
        &:hover {
          animation-play-state: paused;
          li > * {
            animation-play-state: paused;
          }
        }
        @include respond(phone) {
          width: 10rem;
          height: 10rem;
        }
        @include respond(mini-phone) {
          width: 7.5rem;
          height: 7.5rem;
        }
        @include respond(extraMini) {
          width: 6.5rem;
          height: 6.5rem;
        }
      }
      li {
        position: absolute;
        width: 100px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        text-align: center;
        line-height: 1;
        display: grid;
        place-items: center;

        @for $i from 0 through 7 {
          &:nth-child(#{$i + 1}) {
            --throb-delay: #{500ms * $i};
          }
        }
        & > * {
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
          border-radius: inherit;

          position: absolute;
          animation: orbit 120s linear reverse infinite,
            throb 1s var(--throb-delay) ease-in-out infinite alternate;
        }
        .orbit__icon {
          color: $color-light;
          font-size: 40px;

          @include respond(phone) {
            font-size: 30px;
          }
          @include respond(mini-phone) {
            font-size: 24px;
          }
        }
        p {
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
          opacity: 0;
          transition: opacity 500ms;
          border-radius: 50%;
          color: $color-light;
          font-size: 16px;
          font-weight: 600;

          @include respond(phone) {
            font-size: 10px;
          }
        }
        &:hover > p {
          opacity: 1;
        }
      }
      li:nth-child(1) {
        transform: translate(14rem, 0);
        .orbit__icon {
          background-color: $light-purple;
          color: $dark-purple;
        }
        p {
          background-color: $dark-purple;
        }
      }
      li:nth-child(2) {
        transform: translate(10rem, 10rem);
        .orbit__icon {
          background-color: $light-orange;
          color: $dark-orange;
        }
        p {
          background-color: $dark-orange;
        }
      }
      li:nth-child(3) {
        transform: translate(0, 14rem);
        .orbit__icon {
          background-color: $light-blue;
          color: $dark-blue;
        }
        p {
          background-color: $dark-blue;
        }
      }
      li:nth-child(4) {
        transform: translate(-10rem, 10rem);
        .orbit__icon {
          background-color: $light-green;
          color: $dark-green;
        }
        p {
          background-color: $dark-green;
        }
      }
      li:nth-child(5) {
        transform: translate(-14rem, 0);
        .orbit__icon {
          background-color: $light-purple;
          color: $dark-purple;
        }
        p {
          background-color: $dark-purple;
        }
      }
      li:nth-child(6) {
        transform: translate(-10rem, -10rem);
        .orbit__icon {
          background-color: $light-orange;
          color: $dark-orange;
        }
        p {
          background-color: $dark-orange;
        }
      }
      li:nth-child(7) {
        transform: translate(0, -14rem);
        .orbit__icon {
          background-color: $light-blue;
          color: $dark-blue;
        }
        p {
          background-color: $dark-blue;
        }
      }
      li:nth-child(8) {
        transform: translate(10rem, -10rem);
        .orbit__icon {
          background-color: $light-green;
          color: $dark-green;
        }
        p {
          background-color: $dark-green;
        }
      }
      @include respond(phone) {
        li {
          width: 80px;
        }
        li:nth-child(1) {
          transform: translate(11rem, 0);
        }
        li:nth-child(2) {
          transform: translate(8rem, 8rem);
        }
        li:nth-child(3) {
          transform: translate(0, 11rem);
        }
        li:nth-child(4) {
          transform: translate(-8rem, 8rem);
        }
        li:nth-child(5) {
          transform: translate(-11rem, 0);
        }
        li:nth-child(6) {
          transform: translate(-8rem, -8rem);
        }
        li:nth-child(7) {
          transform: translate(0, -11rem);
        }
        li:nth-child(8) {
          transform: translate(8rem, -8rem);
        }
      }
      @include respond(mini-phone) {
        li {
          width: 60px;
        }
        li:nth-child(1) {
          transform: translate(8.5rem, 0);
        }
        li:nth-child(2) {
          transform: translate(6rem, 6rem);
        }
        li:nth-child(3) {
          transform: translate(0, 8.5rem);
        }
        li:nth-child(4) {
          transform: translate(-6rem, 6rem);
        }
        li:nth-child(5) {
          transform: translate(-8.5rem, 0);
        }
        li:nth-child(6) {
          transform: translate(-6rem, -6rem);
        }
        li:nth-child(7) {
          transform: translate(0, -8.5rem);
        }
        li:nth-child(8) {
          transform: translate(6rem, -6rem);
        }
      }
      @include respond(extraMini) {
        li:nth-child(1) {
          transform: translate(7.5rem, 0);
        }
        li:nth-child(2) {
          transform: translate(5rem, 5rem);
        }
        li:nth-child(3) {
          transform: translate(0, 7.5rem);
        }
        li:nth-child(4) {
          transform: translate(-5rem, 5rem);
        }
        li:nth-child(5) {
          transform: translate(-7.5rem, 0);
        }
        li:nth-child(6) {
          transform: translate(-5rem, -5rem);
        }
        li:nth-child(7) {
          transform: translate(0, -7.5rem);
        }
        li:nth-child(8) {
          transform: translate(5rem, -5rem);
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    &__orbit {
      .orbit {
        .center-image {
          width: 10rem;
        }
        ul {
          width: 10rem;
          height: 10rem;

          li {
            width: 80px;
          }
          li:nth-child(1) {
            transform: translate(12rem, 0);
          }
          li:nth-child(2) {
            transform: translate(8rem, 8rem);
          }
          li:nth-child(3) {
            transform: translate(0, 12rem);
          }
          li:nth-child(4) {
            transform: translate(-8rem, 8rem);
          }
          li:nth-child(5) {
            transform: translate(-12rem, 0);
          }
          li:nth-child(6) {
            transform: translate(-8rem, -8rem);
          }
          li:nth-child(7) {
            transform: translate(0, -12rem);
          }
          li:nth-child(8) {
            transform: translate(8rem, -8rem);
          }
        }
      }
    }
  }
}

.hero4-cards {
  position: relative;
  &__wrap {
    z-index: 2;
    &:nth-child(odd) {
      margin-top: -60px;
    }
    &:nth-child(even) {
      margin-top: -30px;
    }
    @include respond(laptop) {
      &:nth-child(odd) {
        margin-top: 0;
      }
      &:nth-child(even) {
        margin-top: 0;
      }
      &:nth-child(1) {
        margin-top: -30px;
      }
      &:nth-child(2) {
        margin-top: -30px;
      }
    }
    @include respond(phone) {
      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
  &__single {
    text-align: center;
    padding: 20px;
    transition: all 0.5s;
    box-shadow: 2px 0px 15px 5px $color-shadow;
    background-color: $color-light;
    border-radius: 5px;
    &:hover {
      .hero4-cards__single__hover {
        opacity: 1;
        transform: scaleY(1);
      }
    }
    h4 {
      margin: 10px 0;
    }
    &__hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      padding: 20px;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: bottom center;
      transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
      color: $color-light;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border-radius: 5px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.85;
        z-index: -1;
        background: $color-secondary;
        border-radius: 5px;
      }
      h4 {
        color: $color-light;
      }
      &__icon {
        color: $color-light;
        margin: 0 auto;
        font-size: 50px;
      }
    }
    &__icon {
      color: $color-primary;
      margin: 0 auto;
      font-size: 50px;
    }
  }
}

.theme-dark {
  .hero4 {
    background-color: $color-dark-primary;
  }
  .hero4-cards {
    background-color: $color-secondary;
    &__single {
      background-color: $color-dark-primary;
      border: 1px solid $color-primary;
    }
  }
}

/////  HERO VERSION 05  /////
.hero5 {
  overflow: hidden;
  background-color: $color-tertiary;
  z-index: -1;
  padding: 170px 0px 100px 0;
  @include respond(phone) {
    padding: 100px 0px 70px 0px;
  }
  &__content {
    @include respond(phone) {
      text-align: center;
    }
  }

  &__image {
    position: relative;
    z-index: 1;
    justify-content: center;

    img {
      border-radius: 5px;
      box-shadow: 2px 0px 15px 5px $color-shadow;
      background: $color-light;
      padding: 10px;
      border: 1px solid $color-shadow;
    }
  }

  &__content {
    z-index: 2;
    &__input__box{
      width: 550px;
      @include respond(big-desktop) {
        width: 100%;
      }
    }
    &__input {
      display: flex;
      align-items: center;
      gap: 6px;
      background-color: $color-light;
      padding: 5px;
      border-radius: 50px;
      border: 1px solid $color-secondary;
      input {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 30px;
        &:focus {
          outline: none;
        }
      }
      .btn__primary {
        width: 285px;
        border-radius: 50px;
        @include respond(big-desktop) {
          width: 250px;
          padding: 10px 0px;
        }
      }
      @include respond(mini) {
        display: block;
        background-color: inherit;
        padding: 0px;
        border-radius: 0px;
        border: none;
        input {
          margin-bottom: 10px;
          border: 1px solid $color-secondary;
        }
        .btn__primary {
          width: 100%;
        }
      }
    }
  }

  .circle {
    position: absolute;
  }
  .circle-1 {
    top: 0;
    animation: moveCircles2 5s linear infinite alternate;
    left: -20px;
    z-index: -1;
    @include respond(phone) {
      display: none;
    }
  }
  .circle-2 {
    right: 0;
    left: inherit;
    bottom: 50px;
    animation: moveCircles2 5s linear infinite alternate;
    z-index: -1;
  }
}

/////  DARK VERSION  /////
.theme-dark {
  .hero5 {
    background-color: $color-secondary;
    .btn__primary {
      &:hover {
        span {
          color: $color-light;
        }
      }
      &::before {
        background-color: $color-secondary;
      }
    }
  }
}

/////  HERO VERSION 06  /////
.hero6 {
  position: relative;
  &-shape {
    position: absolute;
    content: '';
    width: 100px;
    height: 200px;
    // background-image: url('./images/shape.svg');
    background-repeat: no-repeat;
    background-size: cover;
    animation: moveCircles 10s linear infinite alternate;
    top: 150px;
    left: 0;
    z-index: 1;
    @include respond(laptop) {
      display: none;
    }
  }
  &__content {
    padding-right: 40px;
    z-index: 2;
    @include respond(desktop) {
      padding-right: 0px;
    }
    &__input {
      display: flex;
      align-items: center;
      gap: 6px;
      background-color: $color-light;
      padding: 5px;
      border-radius: 50px;
      border: 1px solid $color-secondary;
      width: 550px;
      input {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 30px;
        &:focus {
          outline: none;
        }
      }
      .btn__primary {
        width: 285px;
        border-radius: 50px;
        @include respond(big-desktop) {
          width: 250px;
          padding: 10px 0px;
        }
      }
      @include respond(big-desktop) {
        width: 100%;
      }
      @include respond(mini) {
        display: block;
        background-color: inherit;
        padding: 0px;
        border-radius: 0px;
        border: none;
        input {
          margin-bottom: 10px;
          border: 1px solid $color-secondary;
        }
        .btn__primary {
          width: 100%;
        }
      }
    }
  }
  &__image {
    position: relative;
    width: 600px;
    height: 600px;
    @include respond(big-desktop) {
      width: 550px;
      height: 550px;
    }
    @include respond(desktop) {
      width: 470px;
      height: 470px;
    }
    @include respond(laptop) {
      display: flex;
      margin: 0 auto;
    }
    @include respond(phone) {
      width: 400px;
      height: 400px;
    }
    @include respond(mini-phone) {
      width: 350px;
      height: 350px;
    }
    @include respond(mini) {
      width: 280px;
      height: 280px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: $color-primary;
      border-radius: 50%;
      animation: ripple 5s infinite;
    }
    &::after {
      position: absolute;
      content: '';
      width: 90%;
      height: 90%;
      border-radius: 50%;
      background-color: $color-primary;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.2;
      z-index: 1;
      @include respond(big-desktop) {
        width: 450px;
        height: 450px;
      }
      @include respond(desktop) {
        width: 380px;
        height: 380px;
      }
      @include respond(laptop) {
        display: none;
      }
    }
    .img1 {
      position: relative;
      z-index: 2;
      max-width: 300px;
      margin: 0 auto;
      @include respond(desktop) {
        max-width: 270px;
      }
      @include respond(laptop) {
        max-width: 500px;
      }
      @include respond(phone) {
        width: auto;
      }
    }
    .img2 {
      position: absolute;
      bottom: 65px;
      left: 5%;
      z-index: 3;
      border-radius: 10px;
      width: 200px;
      animation: img2 10s infinite;
      @include respond(big-desktop) {
        width: 170px;
        left: 10%;
      }
      @include respond(desktop) {
        bottom: 0px;
      }
      @include respond(laptop) {
        bottom: 30px;
        left: 5%;
      }
      @include respond(phone) {
        display: none;
      }
    }
    .img3 {
      position: absolute;
      bottom: 65px;
      right: 5%;
      z-index: 3;
      border-radius: 10px;
      width: 200px;
      animation: img1 10s infinite;
      @include respond(big-desktop) {
        width: 170px;
        right: 10%;
      }
      @include respond(desktop) {
        bottom: 0px;
      }
      @include respond(laptop) {
        bottom: 30px;
        right: 5%;
      }
      @include respond(phone) {
        display: none;
      }
    }
  }
}

/////  DARK VERSION  /////
.theme-dark {
  .hero6 {
    background-color: $color-secondary;
    .btn__primary {
      &:hover {
        span {
          color: $color-light;
        }
      }
      &::before {
        background-color: $color-secondary;
      }
    }
  }
}