/*===============
  PORTFOLIO
===============*/
.portfoliov2.bg-one {
  .portfoliov2__single--content {
    background: $color-light;
  }
}

.portfoliov2 {
  &__single {
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    &:hover {
      .portfoliov2__single--content:before {
        height: 100%;
      }
      .portfoliov2__single--content {
        h3,
        a {
          color: $color-light;
        }
      }
    }
    &--image {
      img {
        height: 350px;
        width: 100%;
        object-fit: cover;
        background: $color-tertiary;
        border-radius: 5px;
      }
    }
    &--content {
      background: $color-light;
      margin: -50px auto 0;
      box-shadow: 2px 0px 15px 5px $color-shadow;
      padding: 20px;
      text-align: center;
      position: relative;
      z-index: 1;
      max-width: 320px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 0%;
        background: $color-primary;
        border-radius: 0;
        transition: 0.5s;
      }
      h3 {
        font-size: 18px;
        margin-bottom: 8px;
        transition: 0.4s;
      }
      span {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}

///// DARK VERSION /////
.theme-dark {
  .portfoliov2 {
    background: $color-secondary;
    &__single {
      &--content {
        background-color: $color-dark-primary;
      }
    }
    &.bg-one .portfoliov2__single--content {
      background-color: $color-dark-primary;
    }
  }
}
/*===============
  PORTFOLIO V2
===============*/
.portfolio4 {
  &__slider {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    @include respond(laptop) {
      height: auto;
    }
    &--contnet {
      p {
        background: $color-primary;
        display: inline-block;
        padding: 5px 10px;
        margin-bottom: 5px;
      }
      h5 {
        background: $color-light;
        color: $color-secondary;
        padding: 5px 10px;
      }
    }
    &--ico {
      a {
        width: 45px;
        height: 45px;
        font-size: 22px;
        display: inline-flex;
        background-color: $color-primary;
        align-items: center;
        justify-content: center;
        color: $color-light;
        border-radius: 100%;
      }
    }
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    transition: all 0.3s ease;
    img {
      position: relative;
      min-height: 400px;
      object-fit: cover;
    }

    &.swiper-slide-active {
      color: $color-light;
      img {
        transform: scale(1.4);
      }
      @include respond(phone) {
        transform: scaleX(1);
      }
      z-index: 9999;
      &:hover {
        .swiper-slide__hover {
          opacity: 1;
          transform: scaleY(1);
          top: -50px;
        }
      }
      img {
        object-fit: inherit;
      }
    }

    //hover
    &__hover {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      padding: 10px;
      opacity: 0;
      transform-origin: bottom center;
      transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
      color: $color-light;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 30px;
      @include respond(laptop) {
        justify-content: center;
        gap: 10px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    box-shadow: 2px 0px 15px 5px $color-shadow;
    background-color: $color-primary;
    color: $color-light;
    border-radius: 50%;
    &::after {
      font-size: 16px;
    }
    &:hover {
      background-color: $color-secondary;
    }
  }
  .swiper-button-next {
    right: 30px;
  }
  .swiper-button-prev {
    left: 30px;
  }
}

/////  DARK VERSION  /////
.theme-dark {
  .portfolio4 {
    background-color: $color-secondary;
  }
}

[dir=rtl]{
  .portfolio4 .swiper-button-prev{
    right: 30px;
    left: auto;
  }
  .portfolio4 .swiper-button-next{
    left: 30px;
    right: auto;
  }
}
