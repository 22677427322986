/*===============
  BLOG VERSION 01
===============*/
.blog {
  &__wrapper {
    overflow: hidden;
    .swiper-wrapper {
      margin-bottom: 60px;
    }
  }
  &__item {
    position: relative;
    padding-bottom: 65px;
    &__image {
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
    }
    &__main {
      position: absolute;
      bottom: 0;
      left: 30px;
      right: 30px;
      z-index: 99;
      padding: 32px 28px 22px;
      transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
      transform-origin: bottom center;
      box-shadow: 2px 0px 15px 5px $color-shadow;
      margin-bottom: 15px;
      @include respond(phone) {
        left: 20px;
        right: 20px;
      }
      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: $color-light;
        clip-path: polygon(0 11%, 100% 0, 100% 100%, 25% 100%, 0 100%);
        transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
      }
      &:hover {
        .blog__item__hover {
          transform: scaleY(1);
          opacity: 1;
        }
      }
      ul {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        li {
          display: flex;
          align-items: center;
          gap: 5px;
          color: $color-secondary;
          i {
            color: $color-primary;
          }
          a {
            color: $color-secondary;
          }
        }
      }
      &__title {
        font-size: 20px;
        margin-top: 10px;
        a {
          color: $color-secondary;
        }
      }
    }
    &__hover {
      position: absolute;
      bottom: 0;
      left: 30px;
      right: 30px;
      z-index: 99;
      padding: 45px 20px 20px;
      transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
      transform-origin: bottom center;
      transform: scaleY(0);
      opacity: 0;
      color: $color-light;

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        clip-path: polygon(0 11%, 100% 0, 100% 100%, 25% 100%, 0 100%);
        transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
        background-color: $color-secondary;
        opacity: 0.9;
      }
      &__title {
        font-size: 20px;
        a {
          color: $color-light;
        }
      }
      p {
        line-height: 1.5;
        margin-top: 10px;
      }
      .btn__primary {
        margin-top: 20px;
        letter-spacing: 0;
        padding: 5px 20px;
        border-radius: 3px;
        font-size: 14px;
        &:hover {
          span {
            color: $color-secondary;
          }
        }
        &:before {
          background: $color-light;
        }
      }
    }
    &:hover {
      .blog__item__main {
        opacity: 0;
        transform: scaleY(0);
      }
      .blog__item__hover {
        transform: scaleY(1);
        opacity: 1;
      }
    }
  }
}

/////  DARK VERSION  /////
.theme-dark {
  .blog {
    background-color: $color-dark-primary;
    &__item__main {
      &::before {
        background-color: $color-secondary;
        color: $color-light;
      }
      ul {
        li {
          color: $color-light;
          a {
            color: $color-light;
          }
        }
      }
      h3 {
        a {
          color: $color-light;
        }
      }
    }
  }
}

/*===============
  BLOG VERSION 02
===============*/
.blogv2 {
  &__single {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-shadow;
    padding-bottom: 15px;
    h2 {
      font-size: 25px;
    }
    &--hr {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: 1fr 100px;
      @include respond(desktop) {
        display: block;
      }
      @include respond(laptop) {
        display: grid;
      }
      @include respond(phone) {
        display: block;
      }
      .blogv2__single--image {
        order: 2;
        margin-bottom: 0px;
        @include respond(desktop) {
          margin-bottom: 20px;
        }
        @include respond(phone) {
          order: inherit;
        }
        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          @include respond(desktop) {
            width: 100%;
            height: 207px;
          }
          @include respond(laptop) {
            width: 100%;
            height: 100%;
          }
        }
      }
      .blogv2__single--content {
        h2 {
          margin-bottom: 0px;
        }
      }
      .blogv2__single--content--meta {
        margin-top: 10px;
      }
    }

    &--link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &--image {
      img {
        border-radius: 10px;
      }

      margin-bottom: 20px;
    }

    &--content {
      h2 {
        margin-bottom: 20px;
      }

      &--meta {
        margin-top: 20px;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          grid-gap: 15px;

          li {
            a {
              color: $color-primary;
            }
          }
        }
      }
    }
  }
  &__right {
    &--posts {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 580px;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 0.5em;
      }

      &::-webkit-scrollbar-track {
        background: $color-tertiary;
        margin-block: 0.5em;
      }

      &::-webkit-scrollbar-thumb {
        background: $color-primary;
      }
      &::-webkit-scrollbar-thumb:hover {
        opacity: 0.2;
      }
      @supports (scrollbar-color: $color-primary $color-tertiary) {
        scrollbar-color: $color-primary $color-tertiary;
        scrollbar-width: thin;
        border: none;
      }

      h2 {
        font-size: 22px;
      }

      @include respond(big-desktop) {
        max-height: 550px;
      }
      @include respond(desktop) {
        max-height: 520px;
      }
      @include respond(laptop) {
        width: 100%;
        max-height: 100%;
      }
    }
  }
}

/////  BLOG 2 DARK VERSION  /////
.theme-dark {
  .blogv2 {
    background-color: $color-dark-primary;

    &__single {
      border-bottom: 1px solid $color-secondary;
    }
  }
}

/*===============
  BLOG VERSION 03
===============*/
.blog3 {
  &__card {
    position: relative;
    transition: all 0.5s;
    border-radius: 10px;
    img {
      border-radius: 10px;
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: center;
    }
    .subtitle {
      text-transform: uppercase;
      margin: 10px 0;
      color: $color-primary;
    }
    h5 {
      font-size: 23px;
      font-weight: 700;
    }
    .btn__primary {
      position: absolute;
      bottom: -20px;
      width: 100%;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.5s;
      i {
        font-size: 20px;
        color: $color-light;
      }
    }

    &:hover {
      .postman {
        opacity: 0;
      }
      hr {
        opacity: 0;
      }
      .btn__primary {
        bottom: 0;
        opacity: 1;
        &:hover {
          i {
            color: $color-dark-primary;
            z-index: 1;
          }
        }
      }
    }
  }
}

/////  DARK VERSION  /////
.theme-dark {
  .blog3 {
    background-color: $color-dark-primary;
  }
}
