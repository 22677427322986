// Heading font
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Body font
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

body {
  font-family: $font-display;
  line-height: 1.5;
  color: $color-gray;
}

.theme-dark {
  body {
    color: $color-light;
  }
}

p {
  line-height: 1.8;
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
  color: $color-secondary;
  font-weight: 700;
  margin-bottom: 0;
}

/////  DARK VERSION /////
.theme-dark {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-light;
  }
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  font-weight: 700;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

a {
  text-decoration: none;
}

button,
input {
  &:focus {
    outline: none;
  }
}

  // container adjust
  @media only screen and (min-width: 992px) {
    .container-fluid {
      padding-left: calc((100% - 950px) / 2 + 12px);
      padding-right: 0;
    }
  }
  @media only screen and (min-width: 1200px) {
    .container-fluid {
      padding-left: calc((100% - 1130px) / 2 + 12px);
      padding-right: 0;
    }
  }
  @media only screen and (min-width: 1400px) {
    .container-fluid {
      padding-left: calc((100% - 1320px) / 2 + 12px);
      padding-right: 0;
    }
  }
  @include respond(laptop) {
    .container-fluid {
      max-width: 720px;
      margin: 0 auto;
    }
  }
  @include respond(tablet) {
    .container-fluid {
      max-width: 540px;
      margin: 0 auto;
    }
  }
