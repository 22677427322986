/*===============
  HEADER
===============*/

.header {
  position: absolute;
  width: 100%;
  transition: border-radius 0.2s, width 350ms ease;
  z-index: 999;
  &.navbar_fixed {
    position: fixed;
    width: 100%;
    background-color: $color-light;

    box-shadow: 0 2px 28px 0 $color-shadow;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    .navbar {
      margin-top: 0px;
    }
  }
  .navbar {
    border: 0px;
    padding: 10px 15px;
    top: 0;
    left: 0;
    transition: all 0.3s;
    transition: all 0.4s ease-in-out;
    .navbar-brand {
      h1 {
        color: $color-primary;
        font-weight: 700;
        font-size: 50px;
      }
      padding: 0px;
      img {
        max-width: 211px;
        max-height: 120px;
                background-image: url($imagenFondoClaro);
background-repeat: no-repeat;
      }
      &__logo-white {
        display: none;
      }
    }
    .navbar-nav li a {
      color: $color-secondary;
    }
    .navbar-nav li a:hover,
    .navbar-nav li a.active {
      color: $color-primary;
      background: none;
    }
    .navbar-nav li a:hover:after,
    .navbar-nav li a.active:after {
      opacity: 0.3;
      bottom: 0px;
    }
  }
  .navbar-expand-lg {
    background-color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.4s;
  }
  .navbar-toggler {
    position: relative;
    width: 30px;
    height: 20px;
    border-radius: 0px;
    padding: 0px;
    border: 0px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 50%;
      background: $color-secondary;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      &:nth-child(even) {
        left: 50%;
        border-radius: 0 9px 9px 0;
      }
      &:nth-child(odd) {
        left: 0px;
        border-radius: 9px 0 0 9px;
      }
      &:nth-child(1),
      &:nth-child(6) {
        transform: rotate(45deg);
      }
      &:nth-child(2),
      &:nth-child(5) {
        transform: rotate(-45deg);
      }
      &:nth-child(1) {
        left: 0px;
        top: 4px;
      }
      &:nth-child(2) {
        left: calc(50% - 4px);
        top: 4px;
      }
      &:nth-child(3) {
        left: -50%;
        opacity: 0;
      }
      &:nth-child(4) {
        left: 86%;
        opacity: 0;
      }
      &:nth-child(5) {
        left: 0px;
        top: 14px;
      }
      &:nth-child(6) {
        left: calc(50% - 4px);
        top: 14px;
      }
    }
    &.collapsed {
      span {
        &:nth-child(1),
        &:nth-child(2) {
          top: 2px;
        }
        &:nth-child(3),
        &:nth-child(4) {
          top: 9px;
        }
        &:nth-child(5),
        &:nth-child(6) {
          top: 16px;
        }
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(1),
        &:nth-child(6) {
          transform: rotate(0deg);
        }
        &:nth-child(3) {
          opacity: 1;
          left: 0;
        }
        &:nth-child(4) {
          opacity: 1;
          left: calc(50% - 4px);
        }
      }
    }
  }
}
.dropdown-toggle span {
  transition: all 0.4s;
}
.dropdown-toggle.active.show span {
  transform: rotate(180deg);
}
.menu {
  > .nav-item {
    position: relative;
    transition: all 0.2s linear;
    border-bottom: 1px solid transparent;
    margin-left: 15px;
    cursor: pointer;
    @include respond(desktop) {
      margin-left: 3px;
    }
    a {
      padding-top: 8px;
      padding-bottom: 8px;
      display: block;
      font-weight: 600;
      text-transform: uppercase;
      &::after {
        position: absolute;
        opacity: 0;
        content: '';
        width: 25px;
        height: 25px;
        background-color: $color-primary;
        border-radius: 50%;
        left: -2px;
        top: 7px;
        z-index: -1;
        transition: all 0.5s;
        &:not(:first-child) {
          left: 0px;
        }
      }
    }
    &.submenu {
      .dropdown-menu {
        margin: 0px;
        border: 0px;
        border-radius: 0px;
        left: -30px;
        min-width: 180px;
        padding: 0px;
        background: $color-light;
        top: 100%;
        box-shadow: 0px 6px 20px 0px $color-shadow;
        border-radius: 5px;
        overflow: hidden;
        @media (min-width: 992px) {
          transform: translateY(20px);
          transition: all 0.3s ease-in;
          opacity: 0;
          visibility: hidden;
          display: block;
        }
        .nav-item {
          display: block;
          width: 100%;

          position: relative;
          transition: all 0.3s;
          .nav-link {
            padding: 0px;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
            color: $color-secondary;
            transition: color 0.2s linear;
            padding: 8px 15px;
            &:after {
              display: none;
            }
          }
          &:hover,
          &:focus {
            background-color: $color-primary;
            > .nav-link {
              color: $color-light;
            }
          }
          > .dropdown-menu {
            transition: transform 0.3s ease-in;
            &:before {
              display: none;
            }
            @media (min-width: 992px) {
              position: absolute;
              left: 100%;
              top: -15px;
              opacity: 0;
              display: block;
              visibility: hidden;
              transform: translateY(20px);
              transition: all 0.3s ease-in;
            }
          }
        }
      }
    }
    &:hover {
      .nav-link {
        color: $color-primary;
      }
      @media (min-width: 992px) {
        .dropdown-menu {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          > .nav-item {
            &:hover {
              @media (min-width: 992px) {
                .dropdown-menu {
                  transform: scaleY(1);
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }
}
[dir=rtl] {
  .menu {
    .nav-item {
      margin-right: 15px;
      margin-left: 0;
    }
    .dropdown-menu {
      .nav-item {
        margin-right: 0;
      }
    }
  }
  .dropdown-menu {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .navbar-nav > li .sub-menu-toggle {
    position: absolute;
    top: 0;
    right: 0;
    background: $color-secondary;
    width: 100%;
    height: 38px;
    width: 38px;
    line-height: 38px;
    margin-right: 0px;
    cursor: pointer;
    text-align: center;
    z-index: 1;
    border: 3px solid $color-light;
    color: $color-light;
  }
}
@include respond(laptop) {
  .navbar .navbar-collapse {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: $color-light;
    box-shadow: 4px 6px 10px $color-shadow;
    z-index: 8;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    max-height: 300px;
    overflow-y: scroll;
  }
  .menu > .nav-item {
    margin: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    &.submenu .dropdown-menu {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 10px;
      .nav-item {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.dropdown-toggle::after {
  border-top: 0px;
  margin-left: 0;
}
.switch-box {
  .switch {
    position: relative;
    width: 32px;
    height: 32px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      position: absolute;
      content: '\ef9e';
      font-family: IcoFont;
      color: $color-light;
      font-size: 18px;
      top: 3px;
      font-weight: 700;
      transition: 0.4s;
    }
  }
  input:checked + .slider:before {
    content: '\ee7e';
    color: $color-light;
  }
  .slider {
    &.round {
      border-radius: 50px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}
.theme-light {
  .switch-box .slider {
    background-color: $color-primary;
  }
}
///// DARK VERSION  /////
.theme-dark {
  .header .navbar .navbar-brand__logo-white {
    display: block;

  }
  .navbar-brand__logo-dark {
    display: none;
  }
  .switch-box .slider {
    background-color: $color-primary;
  }
  .header {
    border-bottom: none;
    &.navbar_fixed {
      background: $color-secondary;
    }
    .navbar {
      h1 {
        color: $color-light;
      }
      &-nav {
        li a {
          color: $color-light;
          &:hover,
          &.active {
            color: $color-primary;
            background-color: none;
          }
        }
      }
      .navbar-collapse {
        background-color: transparent;
      }
      @include respond(laptop) {
        .navbar-collapse {
          background-color: $color-secondary;
        }
      }
    }
    .navbar-toggler {
      span {
        background-color: $color-light;
      }
    }
  }
  .nav-item.submenu {
    .dropdown-menu {
      background-color: $color-secondary;
      .nav-item .nav-link {
        color: $color-light;
        &:hover {
          color: $color-light;
        }
      }
    }
  }
}

.theme-dark {
  .header .navbar .navbar-brand {
    &__logo-dark {
      display: none;
    }
    &__logo-white {
      display: block;
    }

      img {
        max-width: 211px;
        max-height: 120px;
        background-image: url($imagenFondoOscuro);
        background-repeat: no-repeat;
      }
  }
  .nav-item.submenu .dropdown-menu {
    background-color: $color-light;
    .nav-item .nav-link {
      color: $color-secondary;
    }
  }
}
