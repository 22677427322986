/*===============
  WHY US
===============*/
.why-us {
  &__content {
    h4 {
      span {
        color: $color-primary;
      }
    }
    &--ico {
      width: 22px;
      height: 22px;
      border-radius: 100%;
      border: 1px solid $color-primary;
      color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
    ul {
      grid-template-columns: 1fr 1fr;
      display: grid;
      grid-gap: 15px;
      @include respond(phone) {
        grid-template-columns: auto;
      }

      li {
        display: grid;
        align-items: center;
        grid-gap: 0.5rem;
        background-color: $color-light;
        grid-template-columns: auto 1fr;
        padding: 12px 20px;
        border-radius: 5px;
      }
    }
  }
  &__image {
    position: relative;
    .video__button {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 70px;
      height: 70px;
      i {
        font-size: 20px;
      }
    }
    
    @include respond(big-desktop) {
      max-width: 700px;
      margin: 0 auto;
    }
  }
}

/////  DARK VERSION  /////
.theme-dark {
  .why-us {
    background-color: $color-secondary;
    &__content {
      ul {
        li {
          background-color: $color-dark-primary;
          color: $color-light
        }
      }
    }
  }
}
