.screenshot-slider {
    padding-bottom: 60px;
    &__image {
        margin-top: 10px;
        margin-bottom: 20px;
        @include respond(laptop) {
            margin-bottom: 0px;
        }
        img {
            border-radius: 30px;
            @include respond(phone) {
                margin: 0 auto;
            }
        }
    }
    &__shape {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        @include respond(big-desktop) {
            display: none;
        }
    }
}

#screenshot {
    .swiper-button-prev {
        left: 20px;
        right: auto;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: $color-primary;
        color: $color-light;
        transition: all 0.5s;
        padding: 8px;

        &::after {
            font-size: 16px;
        }
        &:hover {
            background-color: $color-secondary;
        }
        @include respond(big-desktop) {
            left: 15px;
        }
        @include respond(phone) {
            display: none;
        }
    }
    .swiper-button-next {
        left: auto;
        right: 20px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: $color-primary;
        color: $color-light;
        transition: all 0.5s;
        padding: 8px;
        &::after {
            font-size: 16px;
        }
        &:hover {
            background-color: $color-secondary;
        }
        @include respond(big-desktop) {
            right: 15px;
        }
        @include respond(phone) {
            display: none;
        }
    }
}

.gslide-media .gslide-image {
    background-color: $color-light;
}

/////  DARK VERSION  /////
.theme-dark {
    .screenshot {
        background-color: $color-dark-primary;
    }
    .swiper-button {
        background-color: $color-dark-primary;
        &-prev:hover {
            background-color: $color-secondary;
        }
        &-next:hover {
            background-color: $color-secondary;
        }
    }
}

[dir=rtl]{
    #screenshot .swiper-button-prev{
        right: 20px;
        left: auto;
    }
    #screenshot .swiper-button-next{
        left: 20px;
        right: auto;
    }
}