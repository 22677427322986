.translateEffect1 {
  transition: all 0.3s;
  &:hover {
    transform: translateY(-10px);
  }
}

.translateEffect2 {
  transition: all 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
}

@keyframes preLoader {
  100% {
    top: 100%;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes leftShape {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes middleShape {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(-10px);
  }
}

@keyframes moveCircles {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

@keyframes moveCircles2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50px);
  }
}

@keyframes animationFramesTwo {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes upDown {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
  }
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes orbit {
  100% {
    rotate: 1turn;
  }
}
@keyframes throb {
  100% {
    scale: 1.06;
  }
}
@keyframes ripple {
  0% {
    transform: scale(0.7);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes img1 {
  25% {
    transform: translateX(0px);
  }

  40% {
    transform: translateX(28%);
  }

  60% {
    transform: translateX(28%);
  }

  75% {
    transform: translateX(0px);
  }
}

@keyframes img2 {
  25% {
    transform: translateX(0px);
  }

  40% {
    transform: translateX(-28%);
  }

  60% {
    transform: translateX(-28%);
  }

  75% {
    transform: translateX(0px);
  }
}
@keyframes sImage2 {
  0% {
    transform: translateX(0px);
    z-index: -1;
  }
  40% {
    transform: translateX(-28%);
    z-index: 1;
  }

  60% {
    transform: translateX(-28%);
    z-index: 1;
  }

  75% {
    transform: translateX(0px);
    z-index: -1;
  }
}
@keyframes cf3FadeInOut {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}